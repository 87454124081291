<template>
    <ticket></ticket>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios";
import Ticket from "./Ticket.vue";

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormSelect,
  BFormTextarea,
} from "bootstrap-vue";
import { required, email } from "@validations";

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormSelect,
    ToastificationContent,
    BFormTextarea,
    Ticket
  },
  data() {
    return {
      emailValue: "",
      name: "",
      required,
      description: null,
      service_impact: null,
      service_category: null,
      serviceImpactList: [],
      serviceCategoryList: [],
       timeline: [],
      timeline_day: null,
      timeline_date: null,
      timeline_month: null,
      isLoading: false,
    };
  },
  methods: {
    init() {
      
    },
  },
  mounted() {
    this.init();
  },
};
</script>
